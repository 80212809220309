import { Component, HostListener, OnInit, Renderer2 } from "@angular/core";
import { Store } from "@ngrx/store";
import { TbcoreService } from "@core/services/tbcore.service";
import { PlatformService } from "@core/services/platform-service";
import { constructOnBoardingUrl, isServer } from "@shared/utility/tb-common";
import { INTERNATIONAL_URLS, PYP_LOGIN_HEADER } from "@shared/utility/constants";
import { AuthenticationService } from "@core/services/authentication.service";
import { Router } from "@angular/router";
import { getGoalSelectionPageUrl } from "@shared/utility/goal-utility";
import { selectActivePageType, selectHeaderState } from "@angular-commons/core/application-state/app.selectors";
import { StudentService } from "@angular-commons/core/services/student.service";
import { LocaleDetails, LocalesMap } from "@angular-commons/models/entity/locale/locale.model";

@Component({
  selector: "app-international-header",
  templateUrl: "./international-header.html",
  styleUrls: ["./international-header.scss"],
})
export class InternationalHeader implements OnInit {
  //isLoggedIn: boolean = isLoggedIn();
  //isMobile: boolean = isMobile();

  goalSelectionPageUrl = getGoalSelectionPageUrl();
  headerData: any;
  studentData: any;
  isSideNavCollapsed: boolean;
  isLoggedIn: boolean = this.platformService.isLoggedIn();
  isMobile: boolean = this.platformService.isMobile();
  isServer: boolean = isServer();

  showTargets = false;
  showTargetsUntilClicked = false;

  showGoals = false;

  loginUrl = "/login";
  signupUrl = "/login?tile=signup";
  logoutUrl = "/logout";

  searchModalOpen = false;
  student: any = {};
  showHeaderMweb = false;
  showHeader:boolean = true;
  goalListHandler = () => {};
  targetListHandler = () => {};
  mwebHeaderHandler = () => {};
  urlList = INTERNATIONAL_URLS;

  localeDetails:LocaleDetails;
  hasGoal = false;
  constructor(
    private tbCore: TbcoreService,
    private store: Store<{}>,
    private renderer: Renderer2,
    private platformService: PlatformService,
    private studentService: StudentService,
    private auth: AuthenticationService
  ) {
    this.platformService.pageDetailsUpdated.subscribe(() => {
      this.loginUrl = constructOnBoardingUrl(
        {
          referrer: this.platformService.getPageText(),
          referrerType: this.platformService.getPageType(),
        },
        true,
        this.platformService.href,
        true
      );
      this.logoutUrl = `/logout?redirect_url=/${this.localeDetails.locale}`; 
      this.signupUrl = constructOnBoardingUrl(
        {
          referrer: this.platformService.getPageText(),
          referrerType: this.platformService.getPageType(),
        },
        false,
        this.platformService.href,
        true
      );

    });
    this.setLocaleDetails();
  }

  setLocaleDetails(){
    this.platformService.localisationDetails.subscribe((details:LocaleDetails) => {
      this.localeDetails = details;
    })
  }

  generateUrl(slug:string){
    let url = '';
    if(this.localeDetails?.locale){
      url += `/${this.localeDetails.locale}`
    }
    return url + `${slug}`
  }

  activePageType: string = "";

  ngOnInit() {
    this.hasGoal = !!this.auth.getGoal().id;
    this.store.select(selectActivePageType).subscribe((type) => {
      this.activePageType = type;
    });

    this.studentService.loadStudent(true).then((student) => {
      if (student !== "-1") {
        this.student = student;
      }
    });
    this.store.select(selectHeaderState).subscribe((value) => {
      this.showHeader = value;
    }
    )
  }

  openLoginParams() {
    let pathName = this.platformService.getPathName();
    let isPYP = pathName.includes("previous-year-papers");
    let params = {
      redirect_url: `/${this.localeDetails.locale}`,
      referrer: this.platformService.getPageText(),
      referrerType: this.platformService.getPageType(),
      skipOnboarding: true
    };
    if (isPYP) {
      params["headerText"] = PYP_LOGIN_HEADER;
    }
    return params;
  }
  openLogin(event, isLogin) {
    event.preventDefault();
    //document.body.classList.add('modal-open');
  }

  toggleSearchModal() {
    this.searchModalOpen = !this.searchModalOpen;
  }

  public targetDropdownTouched = false;
  showTargetDropdown(event?) {
    this.showTargets = true;
    this.targetDropdownTouched = true;
    if (event) {
      event.stopPropagation();
    }
    this.targetListHandler = this.renderer.listen(document, "click", () => {
      this.hideTargetDropdown();
    });
  }

  hideTargetDropdown() {
    this.showTargets = false;
    this.targetListHandler();
  }

  showGoalsDropdown(event?) {
    this.showGoals = true;
    if (event) {
      event.stopPropagation();
    }
    this.goalListHandler = this.renderer.listen(document, "click", () => {
      this.hideGoalsDropdown();
    });
  }

  hideGoalsDropdown(event?) {
    this.showGoals = false;
    this.goalListHandler();
  }

  toggleHeaderMweb(event?) {
    this.targetDropdownTouched = true;
    if (!this.showHeaderMweb) {
      this.mwebHeaderHandler = this.renderer.listen(document, "click", () => {
        this.toggleHeaderMweb();
      });
    } else {
      this.mwebHeaderHandler();
    }

    if (event) {
      event.stopPropagation();
    }

    this.showHeaderMweb = !this.showHeaderMweb;
  }
}
