import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { PlatformService } from '@angular-commons/core/services/platform-service';
import {Store} from "@ngrx/store";
import {selectContinueInAppState, selectFooterState} from "@core/application-state/app.selectors";
import {AutoUnsub} from "@shared/utility/tb-common";
import { PROJECT_PLUTUS, PROJECT_TESTBOOK } from '@angular-commons/shared/utility/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
@AutoUnsub()
export class FooterComponent implements OnInit {
  showFooter :boolean = false;
  footerSub$ : any;
  showContinueinApp: boolean = false;
  isMobile:boolean = this.platformService.isMobile();
  isIosDevice = this.platformService.isIosDevice();
  projectName:string = this.platformService.currentProjectName();
  plutusProject:string = PROJECT_PLUTUS;
  testBookProject:string = PROJECT_TESTBOOK;
  isUS: boolean = false;
  constructor(private store:Store<any> , private platformService: PlatformService) {
    this.footerSub$ = store.select(selectFooterState).subscribe( (value)=>{
      this.showFooter = value;
    })
    this.store.select(selectContinueInAppState).subscribe(res => {
      if(res){
        this.showContinueinApp=res;
      }
    });
  }

  ngOnInit() {

  }

}
