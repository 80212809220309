import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router , NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter,map ,mergeMap} from 'rxjs/operators';
import { META_TAGS_TO_MERGE, SEO_ALTERNATE_LINKS_INTERNATIONAL } from '@angular-commons/shared/utility/seo-utilities';
import { isServer } from '@angular-commons/shared/utility/tb-common';
import { PlatformService } from './platform-service';
import { getLanguageQueryParamUrl, getLanguageSegmentUrl, getLocaleSegmentUrl } from '@angular-commons/shared/utility/url';
import { LocalesMap } from '@angular-commons/models/entity/locale/locale.model';

@Injectable()
export class SeoService {
    constructor(private title: Title,
                private meta: Meta,
                @Inject(DOCUMENT) private document,
                private activatedRoute : ActivatedRoute,
                private router: Router,private platformService:PlatformService) { }

    existingLinkTags = [];

    private findExistingLink(attributes: any): HTMLLinkElement | null {
        if(isServer()){ //always add link tag on server side
            return null;
        }
        if(!this.existingLinkTags.length){
            const existingLinks = this.document.head.querySelectorAll('link.spa-meta') as HTMLLinkElement[]; //find all our custom links with spa-meta class
            this.existingLinkTags = existingLinks;
        }
        if(!this.existingLinkTags.length){
            return null;
        }

        this.existingLinkTags.forEach(link => {
            let hasAllAttributes = true;
            for (const att in attributes) {
                if (link.getAttribute(att) !== attributes[att]) {
                    hasAllAttributes = false;
                    break;
                }
            }
    
            if (hasAllAttributes) {
                return link;
            }
        });
        return null;
    }

    private removeAllLinkTags(){
        const existingLinks = this.document.head.querySelectorAll('link.spa-meta') as HTMLLinkElement[];

        if(!existingLinks.length){
            return;
        }

        existingLinks.forEach(link => {
            link.parentNode.removeChild(link);
        });
    }

    addlinkTag( attributes: any) {
        const existingLink = this.findExistingLink(attributes);
        if (!existingLink) {
            const link: HTMLLinkElement = this.document.createElement('link');
            for (let att in attributes) {
                link.setAttribute(att, attributes[att]);
            }
            this.document.head.appendChild(link);
        }
    }

    addSeo = (eventObj: any) => {
        let event = eventObj.tags;
        this.removeAllLinkTags(); //remove all existing link tags first
        for (let e in event){
            if(event[e].type && event[e].type === "title"){
                this.title.setTitle(event[e].attributes.title)
            } else if (event[e].type && event[e].type === "meta"){
                this.addMetaTag(event[e].attributes);
            } else if (event[e].type && event[e].type === "link"){
                this.addlinkTag({class: 'spa-meta' , ...event[e].attributes})
            }
        }
        this.setAlternativeLinks();
    };

    clearSeo(){
        let metaTags = this.document.getElementsByClassName('spa-meta');
        for(let tag of metaTags){
            tag.remove();
        }
    }
    setSeo(){
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        ).subscribe( (event) => {
            this.clearSeo();
            this.addSeo(event);
        });
    }


    setAlternativeLinks() {
        const linkExists = (hreflang,href) => {
            return this.document.querySelector(`link[rel="alternate"][hreflang="${hreflang}"][href="${href}"]`);
        };
        const href = this.platformService.getPathName()
        let alternativeLinks = []
        SEO_ALTERNATE_LINKS_INTERNATIONAL.forEach(item => {
            if (item.urls.some(path => path === href)) {
                alternativeLinks = item.links;
            }
        })
        alternativeLinks.forEach(item => {
            const hreflang = item.hreflang;
            const href = this.platformService.getProtocol()+'//'+this.platformService.getHost()+'/'+item.slug;
            if (!linkExists(hreflang,href) && !linkExists(hreflang,href+'/')) {  // Check if the link already exists
                const attribute = {
                    rel: 'alternate',
                    hreflang: hreflang,
                    href,
                    class: 'spa-href'
                };
                this.addlinkTag(attribute);  // Function to add the link tag
            }
        })
    }

    private addMetaTag(attributes:any = {}) {
        if(!(attributes.name || attributes.property)  || !attributes.content){
            return;
        }
        const existingName = attributes.name || attributes.property;
        const existingMetaTag = this.meta.getTag(`name='${existingName}'`);
        if (existingMetaTag && META_TAGS_TO_MERGE.includes(existingName)) {
          const existingContent = existingMetaTag.content.split(',').map(ele => ele.trim());
          if(!existingContent.includes(attributes.content)){
            const updatedContent = `${existingContent}, ${attributes.content}`;
            this.meta.updateTag({class:'spa-meta', ...attributes, content: updatedContent});
          }
        } else {
          this.meta.addTag({class: 'spa-meta', ...attributes});
        }
    }  

    clearHrefLangs() {
        const existingLinks = this.document.head.querySelectorAll('link.spa-href[hreflang]');
        if (!existingLinks.length) {
            return;
        }
        existingLinks.forEach(link => {
            link.parentNode?.removeChild(link);
        });
    }
    
    setHrefLangs(currentUrl: string, isSegmentStrategy: boolean, availableContentLangs: { code: string; lang: string; }[], locales?: {code:string}[]) {
        this.clearHrefLangs();
        const getLanguageUrl = isSegmentStrategy ? getLanguageSegmentUrl : getLanguageQueryParamUrl;
    
        let isDefaultSet = false;
        // add tags based on available languages
        if(locales?.length){
            let isEnglishIncludes = locales.some(locale => locale.code.toLowerCase() === 'en-in');
            locales.filter(locale => locale.code).forEach(obj => {
                const href = getLocaleSegmentUrl(obj.code, currentUrl);
                this.addlinkTag({ class: 'spa-href', rel: 'alternate', href, hreflang: obj.code });
                if(obj.code === LocalesMap.enUS){
                    this.addlinkTag({ class: 'spa-href', rel: 'alternate', href, hreflang: 'x-default' });
                    isDefaultSet = true;
                }
            });
            if(!isEnglishIncludes){
                const href = getLocaleSegmentUrl(LocalesMap.enIn, currentUrl);
                this.addlinkTag({ class: 'spa-href', rel: 'alternate', href, hreflang: LocalesMap.enIn });
            }
            if(!isDefaultSet){
                const href = getLocaleSegmentUrl(LocalesMap.enIn, currentUrl);
                this.addlinkTag({ class: 'spa-href', rel: 'alternate', href, hreflang: 'x-default' });
            }
        }else{
            this.addlinkTag({ class: 'spa-href', rel: 'alternate', hreflang: 'x-default', href: getLanguageUrl('english', currentUrl) });
            availableContentLangs.forEach(obj => {
                const href = getLanguageUrl(obj.lang, currentUrl);
                this.addlinkTag({ class: 'spa-href', rel: 'alternate', href, hreflang: obj.code });
            });
        }
        
    }
    
}
