<header class="header" *ngIf="showHeader">
  <!-- Main Header for en-us pages -->
  <div class="header__post" [ngClass]="{'open': showHeaderMweb}">
    <div class="logo">
      <div
        class="logo__hamburger js-trigger-hamburger-button"
        (click)="toggleHeaderMweb($event)"
      >
        <span></span>
      </div>
      <a class="logo__testbook" [routerLink]="generateUrl('')" *ngIf="!isMobile">
        <svg>
          <use xlink:href="#testbook-logo"></use>
        </svg>
      </a>
      <a class="logo__testbook" [routerLink]="generateUrl('')" *ngIf="isMobile">
        <svg width="30" height="30">
          <use xlink:href="#testbook-icon"></use>
        </svg>
      </a>
    </div>

    <!-- <ul class="action-nav" *ngIf="!isLoggedIn">
      <li>
        <a
          href="{{signupUrl}}"
          title="Sign Up"
          [attr.data-onboarding-params]="openLoginParams() | json"
          class="btn btn--cta btn--signup ui-js-login"
          translate
          >Get Started</a
        >
      </li>
    </ul> -->

    <!-- Main Header right panel logged in case-->
    <ul class="action-nav" *ngIf="isLoggedIn">
      <li>
        <a
          href="{{logoutUrl}}"
          title="Logout"
          [attr.data-onboarding-params]="openLoginParams() | json"
          class="btn btn--cta"
          translate
          >Logout</a
        >
      </li>
    </ul>

    <div class="container">
      <ul class="main-nav">
        <li>
          <a
            [routerLink]="generateUrl(urlList.SAT_EXAM.url)"
            routerLinkActive="active-link"
            target="_self"
            translate
          >
            <svg class="main-nav__img" width="16" height="16">
              <use xlink:href="#sat-exam-inactive"></use></svg
            >{{urlList.SAT_EXAM.title}}
          </a>
        </li>
        <li>
          <a
            [routerLink]="generateUrl(urlList.ACT_EXAM.url)"
            routerLinkActive="active-link"
            target="_self"
            translate
          >
            <svg class="main-nav__img" width="16" height="16">
              <use xlink:href="#act-exam-inactive"></use></svg
            >{{urlList.ACT_EXAM.title}}
          </a>
        </li>
        <li>
          <a
            [routerLink]="generateUrl(urlList.SAT_PRACTICE_TEST.url)"
            routerLinkActive="active-link"
            target="_self"
            translate
          >
            <svg class="main-nav__img" width="16" height="16">
              <use xlink:href="#free-sat-practice-test-inactive"></use></svg
            >{{urlList.SAT_PRACTICE_TEST.title}}
          </a>
        </li>
        <li>
          <a
            [routerLink]="generateUrl(urlList.ACT_PRACTICE_TEST.url)"
            routerLinkActive="active-link"
            target="_self"
            translate
          >
            <svg class="main-nav__img" width="16" height="16">
              <use xlink:href="#free-act-practice-test-inactive"></use></svg
            >{{urlList.ACT_PRACTICE_TEST.title}}
          </a>
        </li>

        <li class="dropdown">
          <a class="more-nav" translate>Score Guides</a>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              [routerLink]="generateUrl(urlList.SAT_SCORES_GUIDE.url)"
              routerLinkActive="active-link"
              target="_self"
              translate
            >
              <svg class="main-nav__img" width="16" height="16">
                <use xlink:href="#sat-scores-guide-inactive"></use>
              </svg>
              {{urlList.SAT_SCORES_GUIDE.title}}
            </a>
            <a
              class="dropdown-item"
              [routerLink]="generateUrl(urlList.ACT_SCORES_GUIDE.url)"
              routerLinkActive="active-link"
              target="_self"
              translate
            >
              <svg class="main-nav__img" width="16" height="16">
                <use xlink:href="#act-scores-guide-inactive"></use>
              </svg>
              {{urlList.ACT_SCORES_GUIDE.title}}
            </a>
          </div>
        </li>

        <li class="dropdown">
          <a class="more-nav" translate> More </a>
          <div class="dropdown-menu">
            <a
              [routerLink]="generateUrl(urlList.SAT_REGISTRATION.url)"
              class="dropdown-item"
              routerLinkActive="active-link"
              target="_self"
              translate
            >
              {{urlList.SAT_REGISTRATION.title}}
            </a>
            <a
              [routerLink]="generateUrl(urlList.SAT_TEST_DATES.url)"
              class="dropdown-item"
              routerLinkActive="active-link"
              target="_self"
              translate
            >
              {{urlList.SAT_TEST_DATES.title}}
            </a>
            <a
              [routerLink]="generateUrl(urlList.ACT_REGISTRATION.url)"
              class="dropdown-item"
              routerLinkActive="active-link"
              target="_self"
              translate
            >
              {{urlList.ACT_REGISTRATION.title}}
            </a>
            <a
              [routerLink]="generateUrl(urlList.ACT_TEST_DATES.url)"
              class="dropdown-item"
              routerLinkActive="active-link"
              target="_self"
              translate
            >
              {{urlList.ACT_TEST_DATES.title}}
            </a>
            <a
              [routerLink]="generateUrl(urlList.GOOD_ACT_SCORES.url)"
              class="dropdown-item"
              routerLinkActive="active-link"
              target="_self"
              translate
            >
              {{urlList.GOOD_ACT_SCORES.title}}
            </a>
            <a
              [routerLink]="generateUrl(urlList.GOOD_SAT_SCORES.url)"
              class="dropdown-item"
              target="_self"
              translate
            >
              {{urlList.GOOD_SAT_SCORES.title}}
            </a>
            <a
              [routerLink]="generateUrl(urlList.SAT_VS_ACT.url)"
              class="dropdown-item"
              target="_self"
              translate
            >
              {{urlList.SAT_VS_ACT.title}}
            </a>
            <a
            [routerLink]="generateUrl(urlList.COLLEGE.url)"
            class="dropdown-item"
            target="_self"
            translate
          >
            {{urlList.COLLEGE.title}}
          </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="header-backdrop js-trigger-header-backdrop"></div>
  </div>
</header>

