import { Component, OnInit } from "@angular/core";
import { PlatformService } from "@angular-commons/core/services/platform-service";
import { Store } from "@ngrx/store";
import { selectFooterState } from "@core/application-state/app.selectors";
import { AutoUnsub } from "@shared/utility/tb-common";
import { LocaleDetails } from "@angular-commons/models/entity/locale/locale.model";
import { INTERNATIONAL_URLS } from "@angular-commons/shared/utility/constants";

@Component({
  selector: "app-international-footer",
  templateUrl: "./international-footer.component.html",
  styleUrls: ["./international-footer.component.scss"],
})
@AutoUnsub()
export class InternationalFooterComponent implements OnInit {
  showFooter: boolean = false;
  footerSub$: any;
  localeDetails:LocaleDetails;
  urlList = INTERNATIONAL_URLS;

  constructor(
    private store: Store<any>,
    private platformService: PlatformService
  ) {
    this.footerSub$ = store.select(selectFooterState).subscribe((value) => {
      this.showFooter = value;
    });
    this.setLocaleDetails();
  }


  setLocaleDetails(){
    this.platformService.localisationDetails.subscribe((details:LocaleDetails) => {
      this.localeDetails = details;
    })
  }

  generateUrl(slug:string){
    let url = '';
    if(this.localeDetails?.locale){
      url += `/${this.localeDetails.locale}`
    }
    return url + `${slug}`
  }

  ngOnInit() {}
}
