import { DOCUMENT } from "@angular/common";
import { APIConstants } from '../../environments/environment';
import {Inject, Injectable, Optional} from "@angular/core";
import {
    getClientSiteLang,
    getClientCookie,
    setClientCookie,
    isServer,
    getClientQueryParamValue, TARGET_AUTO_ENROLLMENT_KEY,
    loadIntercom, setLocalStorage, getLocalStorage, inIframe, getClientLanguageSegmentFromUrl
} from "@shared/utility/tb-common";
import {
    getClientGoal,
    getClientToken,
    KEY_SELECTED_GOAL,
    KEY_SELECTED_GOAL_SLUG,
    KEY_TOKEN, setClientGoal,
    setClientToken
} from "@shared/utility/user";
import {addInternalScript, loadScript, loadStyle} from "@shared/utility/dynamic-loader";
import {Router} from "@angular/router";
import {serverOnly} from "@shared/utility/platform-decorators";
import {REQUEST, RESPONSE} from "@nguniversal/express-engine/tokens";
import {getPathSegmentsFromURL, getQueryStringFromParams} from "@shared/utility/url";
import {BehaviorSubject, Subject} from "rxjs";
import { languageCodeMap } from "@angular-commons/shared/utility/language";
import { LocaleDetails } from "@angular-commons/models/entity/locale/locale.model";

const DEFAULT_LANG = 'english';
const COOKIE_LANG_KEY = 'language';
const COOKIE_TB_TOKEN = 'tb_token';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    projectName: string = '';

    isServerFlag: boolean = false;

    isServerLoggedInFlag: boolean = false;

    isMobileFlag: boolean = false;

    isIosFlag: boolean = false;

    serverLang: string = 'english';

    pathname: string = '/';

    protocol: string ='https:'

    hostName: string = 'testbook.com';

    query: any = {};

    cookies: any = {};

    token: string = '';

    goal: any = {};

    userAgent: string = '';

    serverDir = '';

    clientPathName = '';

    pageDetailsUpdated = new Subject();
    
    currentLocale = 'en-us';

    localisationDetails = new BehaviorSubject<LocaleDetails>({enabled:false,locale:''});

    private eventTrigger = new Subject<void>();
    eventTrigger$ = this.eventTrigger.asObservable();
  
    triggerPlutusSessionEvent() {
      this.eventTrigger.next();
    }

    public constructor(@Inject(DOCUMENT) private document: Document,
                       @Optional() @Inject(RESPONSE) private response: any,
                       @Optional() @Inject(REQUEST) private request: any,
                       private router: Router) {

    }

    setServerDefaults(userAgent,host , path, cookie, query, protocol) {
        this.setIsMobileFlag(this.isMobileUserAgent(userAgent));
        this.setIsIosFlag(this.isIosUserAgent(userAgent));
        this.setServerPathName(path);
        this.setServerProtocol(protocol);
        this.setServerQueryParamValue(query);

        let lang = this.getServerLanguageSegmentFromUrl() || this.getServerQueryParamValue('language') || cookie && cookie[COOKIE_LANG_KEY] || DEFAULT_LANG;
        this.setServerLang(lang);

        if(cookie) {
            this.setServerCookie(cookie);
        }

        if (cookie && cookie[COOKIE_TB_TOKEN]) {
            this.setIsServerLoggedIn(true);
            this.setServerToken(cookie[COOKIE_TB_TOKEN]);
        } else {
            this.setIsServerLoggedIn(false);
        }
        if(cookie && cookie[KEY_SELECTED_GOAL] && cookie[KEY_SELECTED_GOAL_SLUG]){
            this.setServerSelectedGoal({id:cookie[KEY_SELECTED_GOAL],slug:cookie[KEY_SELECTED_GOAL_SLUG]})
        }
        this.setHost(host);
    }

    isStaticRenderCached = {value:false,cached:false};
    isStaticRender(){
        if(!this.isStaticRenderCached.cached){
            this.isStaticRenderCached.value = this.document.body.classList.contains('tb-static-render');
            this.isStaticRenderCached.cached = true;
        }
        return this.isStaticRenderCached.value;
    }
    nerfTransferState(transferState){
        // makes transferState empty and makes it's set function a noop.
        (<any>transferState).store = {};
        (<any>transferState).set = () => {};
        // creates a forceSet function in case we need to still set something manually.
        (<any>transferState).forceSet = (key,value)=>{
            (<any>transferState).store[key] = value;
        };
    }
    renderStatic(transferState){
        this.isStaticRenderCached.value = true;
        this.isStaticRenderCached.cached = true;
        this.addClass('tb-static-render');
        this.nerfTransferState(transferState);
    }
    renderDynamic(){
        this.isStaticRenderCached.value = false;
        this.isStaticRenderCached.cached = true;
        this.removeClass('tb-static-render');
    }
    checkForStaticRendering(transferState){
        if (!isServer()){ return false; }
        const shouldStaticRender = ( this.getQueryParamValue('force_static') === 'true' || !this.isLoggedIn() ) && !(this.getQueryParamValue('dynamic') === 'true');
        if (shouldStaticRender){
            this.renderStatic(transferState);
        } else {
            this.renderDynamic();
        }
        return this.isStaticRender();
    }

    isServerLoggedIn() {
        return this.isServerLoggedInFlag;
    }

    setIsServerLoggedIn(val) {
        this.isServerLoggedInFlag = val;
    }

    getServerToken() {
        return this.token;
    }

    setServerToken(val) {
        this.token = val;
    }

    getServerSelectedGoal(){
        return this.goal;
    }

    setServerSelectedGoal({id,slug}){
        if(id && slug){
            this.goal = {id:id,slug:slug};
        }
    }

    setIsMobileFlag(val) {
        this.isMobileFlag = val;
    }

    setServerProjectName (val) {
      this.projectName = val;
    }

    getServerProjectName() {
      return this.projectName;
    }

    isMobileSvr() {
        return this.isMobileFlag;
    }

    setIsIosFlag(val) {
        this.isIosFlag = val;
    }

    isIosSvr() {
        return this.isIosFlag;
    }

    setIosFlag(userAgent) {
        this.setIsIosFlag(this.isMobileUserAgent(userAgent));
    }

    setServerPathName(path) {
        this.pathname = path.split('?')[0];
    }

    getServerPathName() {
        return this.pathname;
    }

    setServerProtocol(protocol) {
        this.protocol = `${protocol}:`;
    }

    getServerProtocol() {
        return this.protocol;
    }

    setServerLang(lang) {
        this.serverLang = lang || DEFAULT_LANG;
    }

    getServerLang() {
        return this.serverLang;
    }

    getServerQueryParamValue(key) {
        return this.query[key] || '';
    }

    getServerLanguageSegmentFromUrl() {
        let pathSegments = getPathSegmentsFromURL(this.href);
        if(pathSegments?.[0] && languageCodeMap[pathSegments[0]]){
            return languageCodeMap[pathSegments[0]];
        }
        return '';
    }

    setServerQueryParamValue(query) {
        this.query = query;
    }

    isMobileUserAgent(userAgent) {

        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
            /Mobile/i
        ];

        if (userAgent) {
            return toMatch.some((toMatchItem) => {
                let m = userAgent.match(toMatchItem);
                return m && m.length > 0;
            });
        }
        return false;
    };

    isIosUserAgent(userAgent) {

        const toMatch = [
            /iPhone/i,
            /iPad/i,
            /iPod/i
        ];

        if (userAgent) {
            return toMatch.some((toMatchItem) => {
                let m = userAgent.match(toMatchItem);
                return m && m.length > 0;
            });
        }
        return false;
    };

    getSiteLang() {
        if (this.getPathName().startsWith('/current-affairs-in-hindi')){
            return 'hindi';
        }
        else if (this.getPathName().startsWith('/current-affairs-in-marathi')){
            return 'marathi';
        }
        else if (this.getPathName().startsWith('/current-affairs-in-bengali')){
            return 'bengali';
        }
        let currentLang;
        if (isServer()) {
            currentLang =  this.getServerLang();
        } else {
            currentLang = getClientSiteLang();
        }
        if (['english', 'hindi', 'marathi', 'bengali'].includes(currentLang)) {
            return currentLang;
        }
        return 'english';
    }

    getContentLang(){
        return this.getLanguageSegmentFromUrl() || this.getQueryParamValue('language') ||  'english';
    }
    
    getGoApiServer(){
        let apiServer=this.document.querySelector('#go-api-server');
       if(!apiServer){
            let inputCreated=this.document.createElement('Input');
            inputCreated.setAttribute("type","hidden");
            inputCreated.setAttribute("id","go-api-server");
            inputCreated.setAttribute("value",APIConstants.APIPrefix);
            this.document.body.appendChild(inputCreated);
        }
    }

    isMobile(): boolean {
        if (isServer()) {
            return this.isMobileSvr();
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    isIosDevice(): boolean {
        if (isServer()) {
            return this.isIosSvr();
        }
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    isLoggedIn(): boolean {
        if (isServer()) {
            return this.isServerLoggedIn();
        }
        return !!this.getCookie(KEY_TOKEN);

    }

    currentProjectName(): string {
        if (isServer()) {
            return this.getServerProjectName();
        }
        return this.getCookie('projectName');
    }

    isChrome() {
        if (isServer()) {
            // browser irrelavant on server
            return false
        }
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    }
    getToken () {
        if(isServer()) {
            return this.getServerToken();
        }
        return getClientToken();
    }
    setToken(value, expiry) {
        if(isServer()) {
            this.token = value;
        }
        else {
            setClientToken(value, expiry)
        }
        return this.getToken();
    }

    // to be used internally only, use authentication service's getGoal method instead of this one
    _getGoalCookie() {
        if(isServer()) {
            return this.getServerSelectedGoal();
        }
        return getClientGoal();
    }

    // to be used internally only, use authentication service's setGoal method instead of this one
    _setGoalCookie(value, expiry) {
        if(isServer()) {
            this.setServerSelectedGoal(value);
        } else {
            setClientGoal(value, expiry);
        }
        return this._getGoalCookie();
    }

    setServerCookie(cookies) {
        this.cookies = cookies;
    }

    getServerCookie(key) {
        return this.cookies[key];
    }

    setTargetHintElement(value){
        if(!value){
            return;
        }
        let input = this.document.querySelector(".targetHint");
        if(!input){
            input = this.document.createElement('input');
            input.setAttribute('class', 'targetHint');
            input.setAttribute('type', 'hidden');
            this.document.body.appendChild(input);
        }
        input.setAttribute('value', value);
    }

    setTargetHints(targets){
        // setting target id to be passed for auto-enrollment during signup
        if(!isServer() && !this.isLoggedIn()) {
            window[TARGET_AUTO_ENROLLMENT_KEY] = targets.slice(0, 5).map(t => t.id).join(",");
        }
    }

    getQueryParamValue(key, url?) {

        if(isServer()) {
            return this.getServerQueryParamValue(key);
        }

        return getClientQueryParamValue(key, url);
    }

    getLanguageSegmentFromUrl(){
        if(isServer()) {
            return this.getServerLanguageSegmentFromUrl();
        }
        return getClientLanguageSegmentFromUrl();
    }

    getPathName(getTopWindowPathname?) {
        if(isServer()) {
            return this.getServerPathName();
        }
        if(getTopWindowPathname) {
            try{
                return window.top.location.pathname;
            } catch(e){
                console.error('Cross origin error: ', e);
            }
            return '/';
        }
        return this.clientPathName || window.location.pathname || '/';
    }

    getQueryString(getTopWindowQuerySearch?) {
        if(isServer()) {
            return ''
        }
        if(getTopWindowQuerySearch) {
            try{
                return window.top.location.search;
            } catch(e){
                console.error('Cross origin error: ', e);
            }
            return '';
        }
        return window.location.search;
    }

    setClientPathName(path) {
        this.clientPathName = path;
    }

    getProtocol() {
        // returns with trailing :
        if(isServer()) {
            return this.getServerProtocol();
        }
        return window.location.protocol;
    }

    getCookie(key) {
        if(isServer()) {
            return this.getServerCookie(key);
        }
        return getClientCookie(key);
    }

    setCookie(cname, cvalue, exdays?) {
        if(isServer()) {
            return;
        }
        return setClientCookie(cname, cvalue, exdays);
    }

    setClientBuildDirectory(serverDir){
        this.serverDir = serverDir;
    }
    getClientBuildDirectory(){
        if(isServer()){
            return this.serverDir;
        }
        return 'NOT_ON_SERVER';
    }

    setUserAgent(val) {
        this.userAgent = val;
    }

    getUserAgent() {
        if(isServer()) {
            return this.userAgent;
        }
        return window.navigator.userAgent;
    }

    setHost(host) {
        this.hostName = host;
    }

    getHost() {
        if(isServer()) {
            return this.hostName || 'testbook.com';
        }
        return window.location.hostname;
    }

    redirect(url,status?, forceTopWindowRedirect?){
        if(isServer()){
            if(this.response && !this.response.headersSent){
                // send redirect response from express
                this.response.set('location', url);
                this.response.status(status || 302).send();
            }
            return;
        }
        if (forceTopWindowRedirect){
            try {
                return window.top.location.href = url;
            } catch (e) {
                return window.location.href = url;
            }
        }
        return window.location.href = url;
    }

    hideLivePanel() {
        this.addClass('lp-never');
    }

    showLivePanel() {
        this.removeClass('lp-never');
    }

    addClass(className){
        this.document.body.classList.add(className);
    }

    removeClass(className){
        this.document.body.classList.remove(className);
    }

    containsClass(className){
        return this.document.body.classList.contains(className);
    }

    addInternalScript(script:string){
        return addInternalScript(script,this.document)
    }
    loadScript(url : string){
       return loadScript(url,this.document);
    }

    loadStyle(url : string){
        return loadStyle(url,this.document);
    }

    gotoErrorPage() {
        if (isServer()) {
            if (this.response) {
                this.response.statusCode = 404;
            }
        }
        this.router.navigateByUrl('/error', {skipLocationChange: true});
    }

    @serverOnly()
    setHeader(name,value){
        if(this.response && !this.response.headersSent){
            this.response.set(name,value);
            //let e = new Error("This is not an error:"); // log this to get stack trace
            //console.info(`set header ${name} to ${value}`);
            //console.info(e.originalStack);
        } else {
            console.error(`Headers already sent, cannot set header ${name} to ${value}`);
        }
    }


    get href() {
        if (isServer()) {
            return (this.getProtocol() + '//' + this.getHost() + this.getPathName() + getQueryStringFromParams(this.query));
        }
        return window.location.href || this.getProtocol() + this.getHost() + this.getPathName() + getQueryStringFromParams(this.query);

    }

    get baseUrl() {
        let url = '';
        if (isServer()) {
            url = this.getProtocol() + '//' + this.getHost();
        } else {
            url = window.location.href || this.getProtocol() + this.getHost();
        }
        return url.replace(/\/+$/, '');
    }

    setInputData(id,value){
        let input = this.document.getElementById(id);
        if(!input){
            input = this.document.createElement('input');
            input.setAttribute('id', id);
            input.setAttribute('type', 'hidden');
            this.document.body.appendChild(input);
        }
        input.setAttribute('value', value);
    }

    getInputData(id){
        let value;
        let input = this.document.getElementById('name');
        if (input) {
            value = input.getAttribute('value');
            if(value === 'Others'){
                console.error(`${id} not set : GA events will be impacted`);
            }
        }
        return value || 'Others';
    }

    setPageText(value){
        let input = this.document.getElementById('dlPage');
        if(!input){
            input = this.document.createElement('input');
            input.setAttribute('id', 'dlPage');
            input.setAttribute('type', 'hidden');
            this.document.body.appendChild(input);
        }
        input.setAttribute('value', value);
        this.pageDetailsUpdated.next('setPageText');
    }

    getPageText(){
        let value;
        let input = this.document.getElementById('dlPage');
        if (input) {
            value = input.getAttribute('value');
            if(value === 'Others'){
                console.error('dlpage not set : GA events will be impacted');
            }
        }
        return value || 'Others';
    }

    setPageType(value){
        let input = this.document.getElementById('pageType');
        if(!input){
            input = this.document.createElement('input');
            input.setAttribute('id', 'pageType');
            input.setAttribute('type', 'hidden');
            this.document.body.appendChild(input);
        }
        input.setAttribute('value', value);
        this.pageDetailsUpdated.next('setPageType');
    }

    getPageType(){
        let value : string;
        let input = this.document.getElementById('pageType');
        if(!isServer() && inIframe()){
            try{
                input = window.top.document.getElementById('pageType');
            } catch (e) {
                input = window.document.getElementById('pageType');
                console.error('Cross origin error: ', e);
            }
        }
        if (input) {
            value = input.getAttribute('value');
            if(!value || value === 'Others'){
                console.error('pageType not set : GA events will be impacted');
            }
        }
        return value || 'Others';
    }


    setForceStopRedirectionText(value, setToTop= false){
        let input = this.document.getElementById('stopRedirection');
        if(!input){
            input = !setToTop ? this.document.createElement('input') : top.document.createElement('input');
            input.setAttribute('id', 'stopRedirection');
            input.setAttribute('type', 'hidden');
            this.document.body.appendChild(input);
        }
        input.setAttribute('value', value);
    }

    getForceStopRedirectionText(){
        let value;
        let input = this.document.getElementById('stopRedirection') || top.document.createElement('input');
        if (input) {
            value = input.getAttribute('value');
        }
        return value || 'false';
    }

    addPreloadHint(attributes) {
        if(!isServer()){
            return;
        }
        let link: HTMLLinkElement = this.document.createElement('link');
        for(let att in attributes){
            link.setAttribute( att , attributes[att]);
        }
        this.document.head.appendChild(link);
    }

    hideIntercom() {
        if (isServer()) {
            if(!this.document.getElementById('hideIntercom')){
                let hideIntercomElm = this.document.createElement('div');
                hideIntercomElm.setAttribute('id', 'hideIntercom');
                hideIntercomElm.style.display = "none";
                this.document.body.appendChild(hideIntercomElm);
            }
        }
        this.document.body.classList.add('hideIntercom');
    }

    addClassToBody() {
      if(this.currentProjectName() === 'plutus'){
        this.document.body.classList.add('plutus-app');
      }
    }

    removeClassToBody() {
        if(this.currentProjectName() != 'plutus'){
          this.document.body.classList.remove('plutus-app');
        }
      }

    showIntercom() {
        let hideIntercomElm = this.document.getElementById('hideIntercom');
        if(hideIntercomElm){
            this.document.body.removeChild(hideIntercomElm);
            loadIntercom();
        }
        this.document.body.classList.remove('hideIntercom');
    }

    setTempSessionStorage(keyname, value){
        let lsTempSessionStorage = getLocalStorage(keyname);
        if(lsTempSessionStorage){
            return;
        } else {
            setLocalStorage(keyname, value,30/1440);
        }
    }

    dispatchCustomEvent(eventName, params?){
        if (!params) {
            params = {};
        }
        const event = new CustomEvent(eventName, {detail: params});
        if (event){
            return this.document.dispatchEvent(event);
        }
    }

    getReferrer() {
        if(isServer()) {
            return this.request.headers['Referer'];
        }
        return this.document.referrer || "";
    }

    postMessageToParentWindow(params:any,target:string= "*"){
        try{
            window.top.postMessage(params,target);
          }
          catch (e) {
            try{
                window.parent.postMessage(params, target);
            }
            catch (e){
                window.postMessage(params, target);
            }
          }
    }

    // isInternational() {
    //     return this.currentLocale === 'en-us';
    // }
}
