import { Injectable , Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {
    constructOnBoardingUrl,
    getLocalStorage, getPageText, isServer, getPageType,
    removeLocalStorage,
    setLocalStorage, TARGET_AUTO_ENROLLMENT_KEY
} from "@shared/utility/tb-common";
import { Store ,select} from '@ngrx/store';
import {
    getStudentMe,
    justLoggedIn, setActivePageType,
    setSubFooterType,
    showMobileSignupAndVerificationModal,
    showPricingModal,
    stickyFooterType
} from "../application-state/app.actions";
import {selectStudent} from "../application-state/app.selectors";
import {BehaviorSubject, Observable} from 'rxjs';
import {ModelManager} from "./model-manager.service";
import {filter, map, mergeMap} from "rxjs/operators";
import {PlatformService} from "@core/services/platform-service";
import {getDeferredPromise} from "@shared/utility/defer-promise";
import { GAEventService } from './ga-event.service';
import {signInEvent, signUpEvent} from "@shared/utility/ga-events";
import {setStudentData} from '@shared/utility/user';
import {NetworkService} from "@core/services/network.service";
import {studentMeApi} from "@models/entity/student/student.adapter";
import {getLanguageQueryParamUrl, getLanguageSegmentUrl, getLocaleSegmentUrl, getPathSegmentsFromURL, getRedirectUrl} from "@shared/utility/url";
import { StudentService } from './student.service';
import {getElementBySelector, toggleElementVisibility, toggleModal} from "@shared/utility/dom";
import { getFingerprintID } from '@angular-commons/shared/utility/vanilla';
import { SeoService } from './seo.service';
import { LocaleDetails, LocalesMap } from '@angular-commons/models/entity/locale/locale.model';

declare var showLoader,hideLoader, $, trueCallerSdk;


@Injectable({
    providedIn: 'root'
})
export class TbcoreService {
    loginCallbackActions:any = {};

    postLoginActionsKey = 'postLoginCBs';
    postLoginCallbacks: any = {};

    webengageScriptTries=0;
    isMobile: boolean = this.platformService.isMobile();
    hideIntercomElAdded = false;


    localeDetails: LocaleDetails;
    locale: string = '';
    public currentLang$;


    loginCallback(key,fn){
        if(!(key && fn)){
            return '';
        }
        let actualKey = 'callback_' + key;
        if(!this.loginCallbackActions[actualKey]){
            this.loginCallbackActions[actualKey] = fn;
        }
        return actualKey;
    }
    isMPage = false;
    constructor(@Inject(DOCUMENT) private document,private router: Router, private activatedRoute: ActivatedRoute, private store: Store<{ app: boolean }>,private modelManager: ModelManager,private studentService:StudentService, private seoService: SeoService,
    private platformService: PlatformService, private gaEventService: GAEventService, private network: NetworkService){
        this.platformService.localisationDetails.subscribe((details) => {
          this.localeDetails = details;
          this.locale =
            this.localeDetails.locale &&
            this.localeDetails.locale !== LocalesMap.enIn
              ? this.localeDetails.locale
              : "";
        }); 

        this.isMPage = this.platformService.getQueryParamValue('mPage') === '1';
        this.currentLang$ = new BehaviorSubject(platformService.getSiteLang());


        if(document && document.addEventListener) {
            document.addEventListener('tb-login', (evtData) => {
                if(evtData && evtData.detail && evtData.detail.token) {
                    this.platformService.setCookie('tb_token', evtData.detail.token);
                    this.studentService.loadStudent();
                    this.store.dispatch(justLoggedIn());
                }
            })
        }

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        )
            .subscribe((event) => {

                if(event.hideIntercom && isServer() && !this.hideIntercomElAdded) {
                    this.hideIntercomElAdded = true;
                    var el = this.document.createElement('input');
                    el.id = 'hideIntercom';
                    el.value = true;
                    el.type = 'hidden';
                    this.document.querySelector('body').appendChild(el);
                }
                if(event.hideIntercom){
                    this.platformService.hideIntercom();
                } else {
                    this.platformService.showIntercom();
                }
                event?.subFooterType && this.store.dispatch(setSubFooterType({subFooterType: event.subFooterType}));
                event?.pageType && this.store.dispatch(setActivePageType({pageType: event?.pageType}));

            });

        if(!isServer() && this.platformService.isLoggedIn() && this.platformService.getQueryParamValue('trigger') === 'buypass') {
            this.store.dispatch(showPricingModal({}));

        }
    }

    reloadCurrentRoute() {
        window.top.location.reload();
    }

    setIframeUrl(selector, url) {
        let frame;
        frame = getElementBySelector(selector);

        if (frame){
            // apparently for some browsers (safari) setting iframe url does not mean navigate to the page only "set the url"
            frame.contentWindow.location.href = url;
            frame.addEventListener('load', () => {
                toggleElementVisibility('.js-inject-iframe-loader', false);
            });
        }
    }

    openModal(params, isLogin) {
        if (params?.isMobileSignUpVerification || this.isMPage) {
            params.headerText = params.headerText || 'Login to continue';
            params.show=true;
            this.store.dispatch(showMobileSignupAndVerificationModal({data: params,isSkippable:true}));
        } else {
            params.redirect_url = params.redirect_url || this.loginCallback(params.key,params.callback);
            delete params.key;
            delete params.callback;

            if(params.postLoginKey) {
                this.postLoginCallbacks.key = params.postLoginKey;
                this.postLoginCallbacks.action = params.postLoginAction;
            }

            params['locale'] = this.locale;

            var url = constructOnBoardingUrl(params, isLogin);
            this.setIframeUrl('.js-tb-on-boarding','about:blank');
            setTimeout(()=>{
                this.setIframeUrl('.js-tb-on-boarding',url);
                this.openOnBoardingModal();
            },100);
        }
    }

    postTruecallerFn(params,response){
        try{
            let eventInfo = {
                ...params.gtmExtras,
                medium: 'Truecaller',
                page: getPageText(),
                pageType: getPageType(),
                pagePath:  this.platformService.getPathName(true) + this.platformService.getQueryString(true),
            };
            let event = (response.data.isSignUp) ? new signUpEvent(eventInfo) : new signInEvent(eventInfo);

            this.fireEventWithSid(event,false).finally(() => {
                if (params?.redirect_url) {
                    let redirectUrl = decodeURIComponent(params.redirect_url);
                    let url = getRedirectUrl(redirectUrl, window.location.pathname);
                    this.platformService.redirect(url, 302, true);
                }
                else {
                    window.top.location.reload();
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }

    openSignUpSignInModal(params: any = {}, isLogin , disableTrueCaller = false) {
        if (!this.platformService.isLoggedIn()) {
            if(window[TARGET_AUTO_ENROLLMENT_KEY]) {
                params.gtmExtras = (typeof params.gtmExtras !== "object" ? JSON.parse(params.gtmExtras || "{}") : params.gtmExtras) || {};
                params.gtmExtras[TARGET_AUTO_ENROLLMENT_KEY] = window[TARGET_AUTO_ENROLLMENT_KEY];
                params.gtmExtras = JSON.stringify({ ...params.gtmExtras });
            }
            this.openModal(params, isLogin);

            if(!disableTrueCaller && this.isMobile && this.platformService.isChrome() && typeof trueCallerSdk !== 'undefined') {
                window['trueCallerSdk'].initFlow().then((response) => {
                    if (response && response.data && response.data.token) {
                        this.platformService.setCookie('tb_token', response.data.token, response.data.tokenExpiry / (60 * 60 * 24));
                        this.postTruecallerFn(params,response);
                    }

                }).catch((error) => {
                    console.error(error);
                });
            }
        }
        else if (this.platformService.isLoggedIn()  && params?.isMobileSignUpVerification) {
            this.store.dispatch(showMobileSignupAndVerificationModal({data: params,isSkippable:true}));
        }
        else {
            this.platformService.dispatchCustomEvent('logged-in', { key: params.redirect_url || this.loginCallback(params.key, params.callback) || ''});
        }
    }


    openOnBoardingModal() {
        setTimeout(function(){
            toggleModal('#tbSignUpModal'),
            toggleElementVisibility('#tbSignUpModal', true)
        }, 0);
    }


    clearModelManagerCache(){
        this.modelManager.cacheStore.clear();
    }

    loginActions(){
        if(this.document && this.document.addEventListener) {
            this.document.addEventListener('logged-in', (e) => {
                let data = e.detail;
                window.scrollTo(0, 0);
                if(this.postLoginCallbacks.key) {
                    setLocalStorage(this.postLoginActionsKey, this.postLoginCallbacks);
                }
                if(data.token) {
                    this.platformService.setCookie('tb_token', data.token);
                }
                this.studentService.loadStudent()
                    .then( () => {
                        if (data.key && data.key.startsWith('callback_') && this.loginCallbackActions[data.key] !== undefined){
                            this.loginCallbackActions[data.key]();
                        } else if(data.key && !data.key.startsWith('callback_') ) {
                            try{
                                window.top.location.href = data.key;
                            }
                            catch (e) {
                                window.parent.location.href = data.key
                            }
                        } else {
                            //window.location.reload();
                            this.clearModelManagerCache();
                            this.reloadCurrentRoute();
                            showLoader("Loading");
                            setTimeout(hideLoader,500);
                        }
                    })
                    .finally( () => {
                        toggleModal('#tbSignUpModal', false);
                        this.setIframeUrl('.js-tb-on-boarding','about:blank');
                    });
            });
        }
    }

    fireEventWithSid(event,fireToLocalStorage = false) {
        let fetchUserDataPromise = getDeferredPromise();
        if(isServer()){
            // no student me or events on server
            fetchUserDataPromise.reject();
            return fetchUserDataPromise.promise;
        }
      //we add a provision to fire event post login for yolo
      //this is because webengage is not loaded in logged out mode anymore

      if (event && (event.sid == '-1' || !event.sid)) {
        studentMeApi.apiCall(this.network, {}).toPromise()
          .then((studentData: any) => {
              event.sid = studentData.data._id;
              setStudentData(studentData.data);
              if(!fireToLocalStorage) {
                  this.gaEventService.pushToDataLayer({
                      ...event,
                      'eventCallback' : function() {
                          fetchUserDataPromise.resolve();
                      }
                  });
                  // fallback in case gtm is adblocked or something
                  setTimeout(() => fetchUserDataPromise.resolve(),5000);
              } else {
                  this.gaEventService.pushToLocalStorage(event);
                  fetchUserDataPromise.resolve();
              }
          }).catch((error) => {
              console.log("tbcore: error getting student",error);
              fetchUserDataPromise.reject();
              if(error?.error && !error?.error?.success){
                this.platformService.redirect('logout',302,true)
              }
          });
      } else {
          if(!fireToLocalStorage) {
              this.gaEventService.pushToDataLayer({
                  ...event,
                  'eventCallback' : function() {
                      fetchUserDataPromise.resolve();
                  }
              });
              // fallback in case gtm is adblocked or something
              setTimeout(() => fetchUserDataPromise.resolve(),5000);
          } else {
              this.gaEventService.pushToLocalStorage(event);
              fetchUserDataPromise.resolve();
          }
      }
      return fetchUserDataPromise.promise;
    }


    openPaymentModal(params) {
        toggleElementVisibility('.js-inject-iframe-loader')
        this.setIframeUrl('.js-tb-payment-flow-frame','/pricing?' + params);
        setTimeout(function () {
            toggleModal('#tbPaymentFlowModal');
        }, 100);
    }


    paymentActions(){
        window["paymentModalClose"] = () => {
            toggleModal('#tbPaymentFlowModal', false);
            this.setIframeUrl('.js-tb-payment-flow-frame','about:blank');
        };

        if (this.document && this.document.addEventListener) {
            this.document.addEventListener('payment-done', (e) => {
                this.reloadCurrentRoute();
                toggleModal('#tbPaymentFlowModal', false);
                this.setIframeUrl('.js-tb-payment-flow-frame','about:blank');
                showLoader("Loading");
                setTimeout(hideLoader,500);
            });
            this.document.addEventListener('payment-started', (e) => {
                toggleModal('#tbPaymentFlowModal', false);
                this.setIframeUrl('.js-tb-payment-flow-frame','about:blank');
            });
        }
    }

    clearPostLoginActions() {
        removeLocalStorage(this.postLoginActionsKey);
    }

    callPostLoginActions(zone, key) {
        let map = getLocalStorage(this.postLoginActionsKey);
        if(map && map[key] && typeof zone[map[key]] === 'function') {
            setTimeout(zone[map[key]], 0);
            removeLocalStorage(this.postLoginActionsKey);
        }
    }

    getStudentsTargets(student){
        let data = '';
        if(student.targetInfo && student.targetInfo.length > 0){
            data = student.targetInfo.map(info => info?.title?.[0]?.value).join(', ')
        }
        return data;
    }

    getStudentsGoals(student){
        let data = '';
        if(student.goalEnrolled && student.goalEnrolled.length > 0){
            data = student.goalEnrolled.map(info => info?.id).join(', ')
        }
        return data;
    }

    getStudentsPurchaseGoals(student){
        let data = '';
        if(student.goalSubs && student.goalSubs.length > 0){
            data = student.goalSubs.map(info => info?.goalTitle[0]?.value).join(', ')
        }
        return data;
    }

    getGoalExpiryDate(student){
        let superCoachingExpiry = []
        let superExpiryDate = ''
        if(student.goalSubs){
            for (let i = 0; i < student.goalSubs.length; i++) {
                superCoachingExpiry.push((student.goalSubs[i].expiry))
            }
            superCoachingExpiry = superCoachingExpiry.sort()
            superExpiryDate = superCoachingExpiry[superCoachingExpiry.length - 1]
        }
        return superExpiryDate
    }
    getActiveTargetIds(student){
        let data = '';
        if(student.goalSubs && student.goalSubs.length > 0){
            data = student.activeTargetIds.map(info => info).join(', ')
        }
        return data;
    }
    getActiveGoalIds(student){
        let data = '';
        if(student.goalSubs && student.goalSubs.length > 0){
            data = student.activeGoalIds.map(info => info).join(', ')
        }
        return data;
    }

    getEnrolledSuperGroup(student){
        let data = '';
        if(student.goalSubs && student.goalSubs.length > 0){
            data = student.activeSuperGroupIds.map(info => info).join(', ')
        }
        return data;
    }

    async firePageReadyEvent() {
        if (isServer()) {
            return;
        }
        if(window['dataLayer']) {
            if (!this.platformService.isLoggedIn()) {
                if(!this.platformService.isStaticRender()){
                    let studentInfo = {
                        sid: -1,
                        fpId: await getFingerprintID(),
                        page: getPageText() || '',
                        pagePath:  this.platformService.getPathName()+ this.platformService.getQueryString(),
                    }
                    this.gaEventService.pushToDataLayer(studentInfo,true)
                    this.gaEventService.pushToDataLayer({
                        event: "page_ready",
                    })
                }
            } else {
                this.studentService.loadStudent(true).then( async response => {
                    if (response.id !== "-1") {
                        var firstName = '';
                        var lastName = '';
                        if (response.name) {
                            if (response.name.trim().indexOf(' ') != -1) {
                                firstName = response.name.trim().substr(0, response.name.trim().indexOf(' '));
                                lastName = response.name.trim().substr(response.name.trim().indexOf(' ') + 1);
                            } else {
                                firstName = response.name.trim();
                                lastName = '';
                            }
                        }
                        var mobile = response.mobileVerified ? response.mobileVerified : (response.mobile ? response.mobile : '');
                        var isMobileConfirmed = !!response.mobileVerified;
                        var isMailConfirmed = !!(response.confirm && response.confirm.email && response.confirm.email.status);
                        var isReferred = response.referrer ? (!!response.referrer.sid) : false;
                        var isOCRMStudent = (response.cobrandingInfo && response.cobrandingInfo.validTill) ? ((new Date(response.cobrandingInfo.validTill).getTime() >= new Date().getTime())) : false;
                        if (response.currentCourse) {
                            response.currentCourse.abbr = response.currentCourse.name;
                            delete response.currentCourse.name;
                        }
                        var tblang = '';
                        if (response.tblang) {
                            if (response.tblang === 'hn') {
                                tblang = 'Hindi';
                            } else if (response.tblang === 'en') {
                                tblang = 'English';
                            }
                        }

                    let enrolledTargets = this.getStudentsTargets(response);
                    let enrolledGoals = this.getStudentsGoals(response);
                    let purchasedGoals:string = this.getStudentsPurchaseGoals(response);
                    let superExpiryDate:string = this.getGoalExpiryDate(response)
                    let activeTargetIds:string = this.getActiveTargetIds(response);
                    let activeGoalIds:string = this.getActiveGoalIds(response);
                    let enrolledSuperGroup:string = this.getEnrolledSuperGroup(response);

                        let studentInfo: any = {
                            sid: response.id || '',
                            fpId: await getFingerprintID() || '',
                            signUpDate: response.createdOn || '',
                            isEmailConfirmed: isMailConfirmed,
                            isReferred: isReferred,
                            isMobileConfirmed: isMobileConfirmed,
                            currentGoal: response.currentGoal || '',
                            enrolledGoal: enrolledGoals || '',
                            enrolledTargets: enrolledTargets || '',
                            activeTargets: activeTargetIds || '',
                            activeGoal: activeGoalIds || '',
                            purchasedGoals: purchasedGoals || '',
                            enrolledSuperGroup: enrolledSuperGroup || '',
                            globalPassExpiryDate: response.globalPassExpiry.expiry || '',
                            globalSuperExpiryDate: superExpiryDate || '',
                            page: getPageText() || '',
                            pagePath:  this.platformService.getPathName()+ this.platformService.getQueryString(),
                        };
                        // For Free Demo Pass
                        if (response.passes && response.passes.length && response.passes[0].txn_id === 'demo') {
                            studentInfo.demoDays = response.passes[0].validity / (10e8 * 3600 * 24);
                        }


                        this.gaEventService.pushToDataLayer(studentInfo,true)
                        this.gaEventService.pushToDataLayer({
                            event: "page_ready"
                        })
                    }
                }).catch((e)=>{
                    console.error(e)
                })
            }
        } else if(this.webengageScriptTries < 5) {
            this.webengageScriptTries += 1;
            setTimeout(() => {
                this.firePageReadyEvent()
            }, 1000)
        }
    }

    setCanonicalLink(url = '/') {
        let attributes = {'rel' : 'canonical', 'href' : url};
        this.seoService.addlinkTag(attributes);
    }

    addAmpLink(url = '/') {
        let attributes = {'rel' : 'amphtml', 'href' : url};
        this.seoService.addlinkTag(attributes);
    }

    addHrefLang(url = '/', hreflang = '', code, xdefault= false, noQueryParam = false, languageSegment = false, localeSegment = false) {
        let attributes = {'rel' : 'alternate'};
        if(xdefault){
            attributes['href'] = url;
            attributes['hreflang'] = 'x-default';
        }else{
            if (code === 'en' || noQueryParam || code === LocalesMap.enIn){
                attributes['href'] = url;
            }else if(languageSegment){
                attributes['href'] = getLanguageSegmentUrl(hreflang, url);
            } else if(localeSegment){
                attributes['href'] = getLocaleSegmentUrl(hreflang, url);
            }
            else {
                attributes['href'] = getLanguageQueryParamUrl(hreflang, url);
            }
            attributes['hreflang'] = code;
        }
        this.seoService.addlinkTag(attributes);
    }
}
