import { FooterComponent } from "@angular-commons/feature-modules/footer/footer.component";
import { InternationalFooterComponent } from "@angular-commons/feature-modules/footer/international-footer/international-footer.component";
import { HeaderComponent } from "@angular-commons/feature-modules/header/header.component";
import { InternationalHeader } from "@angular-commons/feature-modules/header/international-header/international-header";
interface LocaleConfig {
  headerComponent: any;
  footerComponent: any;
}

export const LOCALE_CONFIG: { [key: string]: LocaleConfig } = {
  default: {
    headerComponent: HeaderComponent,
    footerComponent: FooterComponent,
  },
  'en-us': {
    headerComponent: InternationalHeader,
    footerComponent: InternationalFooterComponent
  }
};

export function getLocaleConfig(locale:string = 'default'){
  return LOCALE_CONFIG[locale] || LOCALE_CONFIG['default'];
}
