
import {Component, OnInit, Renderer2} from '@angular/core';
import {Store} from "@ngrx/store";
import {TbcoreService} from "@core/services/tbcore.service";
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {getGoals, goalPagePopupState} from "@core/application-state/app.actions";
import { selectGoals, selectActivePageType} from "@core/application-state/app.selectors";
import {TransferStateManager} from "@core/services/transferStateManager";
import {AuthenticationService} from "@core/services/authentication.service";
import { NavigationEnd, Router,RouterEvent } from '@angular/router';
import { getGoalSelectionPageUrl} from "@shared/utility/goal-utility";
import { CURRENT_PRACTICE_GROUP_DATA_KEY } from '@angular-commons/shared/utility/constants';
import { StudentService } from '@angular-commons/core/services/student.service';

@Component({
  selector: 'app-header-logged-in',
  templateUrl: './header-logged-in.html',
  styleUrls: ['./header-logged-in.scss']
})
export class HeaderLoggedIn implements OnInit {
  goalSelectionPageUrl = getGoalSelectionPageUrl();
  // educatorsPageUrl = getEducatorsPageUrl();
  headerData: any;
  isMobile: boolean = this.platformService.isMobile();
  isServer: boolean = isServer();
  showGoalsPopup : boolean = false;

  goals$ = this.store.select(selectGoals);
  goalsTransferState$ : any;
  goals = [];
  goal;
  currentGoalDetails;

  searchModalOpen = false;
  student: any = {};
  showGoalDropdownSection:boolean=false;
  goalListHandler = () => {};
  showGoalsPage:boolean=false;
  showSidebar:boolean = false;

  

  constructor(private store: Store<{}>,
              private renderer: Renderer2,
              private platformService: PlatformService,
              private transferStateManager: TransferStateManager,
              private auth:AuthenticationService,
              private studentService:StudentService,
              private router:Router) {

        function isRouterNavigationEndEvent(ev: any): ev is { routerEvent: NavigationEnd } {
          return ev?.routerEvent instanceof NavigationEnd;
        }

        router?.events.subscribe((ev: any) => {
          if (isRouterNavigationEndEvent(ev)) { 
            this.goal = this.auth.getGoal();
            if (this.goals?.length) {
              this.currentGoalDetails = this.goals.find(goal => goal.id === this.goal.id);
            }
            this.checkIfOnCurrentGoalPage();
          }
        });
  }



  activePageType:string=''

  ngOnInit() {
    this.goalsTransferState$ = this.transferStateManager.get('allGoals', getGoals(), this.goals$,(value)=>{
      if(value){
        this.goals = value.goals;
      }
    });
    this.studentService.loadStudent(true).then( student => {
      if(student !== '-1') {
        this.student = student;
        if (student?.currentStudyGroup) {
          this.platformService.setCookie(CURRENT_PRACTICE_GROUP_DATA_KEY,student.currentStudyGroup)
        }
      }  
    })


    this.store.select(selectActivePageType).subscribe((type)=>{
        this.activePageType=type;
    })
  }

  checkIfOnCurrentGoalPage() {
    const pathname = this.platformService.getPathName().split('?')[0];
    const pathArr = pathname.split('/');
  
    this.showGoalDropdownSection = (
      pathArr.length === 2 &&
      pathArr[0] === '' &&
      pathArr[1].endsWith('-coaching') &&
      pathArr[1] !== 'super-coaching'
    );
  }
  
  


  checkIfOnGoalPage(){
    let pathname=this.platformService.getPathName();
    pathname=pathname.split('?')[0];
    let pathArr=pathname.split('/')
    if(pathArr?.length === 2 && pathArr[0] === '' && pathArr[1].endsWith('-coaching') && pathArr[1] !== 'super-coaching') {
        this.showGoalDropdownSection=true;
    }
    else{
      this.showGoalDropdownSection=false;
    }
  }

  toggleSearchModal() {
    this.searchModalOpen = !this.searchModalOpen;
  }

  showGoalsDropdown(event?) {
    this.showGoalsPopup = true;
    if(event) {
      event.stopPropagation();
    }
    this.goalListHandler = this.renderer.listen(document, 'click', () => {this.hideGoalsDropdown()});
  }

  hideGoalsDropdown(event?) {
    this.showGoalsPopup = false;
    this.goalListHandler();
    this.store.dispatch(goalPagePopupState({status: false}));
  }
  hideGoalPopup(){
    this.showGoalsPage = false;
    this.store.dispatch(goalPagePopupState({status: false}));
  }
  showGoalPopup(){
    if(this.platformService.containsClass('emi-iframe-open')){
      this.platformService.redirect('/super-coaching?stay=1');
    }
    this.showGoalsPage = true;
    this.store.dispatch(goalPagePopupState({status: true}));
  }

  toggleSideBar(){
    this.showSidebar = !this.showSidebar;
  }

}
