import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Store} from "@ngrx/store";
import {
  getWildCardChildRoutes, resetWildCardChildRoutes, resetWildCardRoutes
} from "../route-modules/wild-card-routes/application-state/WildCardRoutesActions";
import {selectWildCardChildRoutes} from "../route-modules/wild-card-routes/application-state/WildCardRoutesSelector";
import {skipInitialState} from "../../../../angular-commons/shared/utility/ngrx-utils";
import {map} from "rxjs/operators";
import {CUSTOM_CHILD_MAX_NEXT_CROSS_LINKS, CUSTOM_CHILD_MAX_PREV_CROSS_LINKS, CUSTOM_CHILD_MAX_TOTAL_CHILD_PAGES, PAGE_TYPE_CUSTOM, PAGE_TYPE_TARGET, currentAffairsRegex} from "../../../../angular-commons/shared/utility/constants";
import {PlatformService} from "../../../../angular-commons/core/services/platform-service";
import {getDateByMonth, getMonthIndex} from "@shared/utility/date-utilities";
import {getMonthlyCurrentAffairs} from "@core/application-state/app.actions";
import { langMap, languageCodeMap } from '@angular-commons/shared/utility/language';
import { getLanguageSegmentUrl, getOriginFromURL } from '@angular-commons/shared/utility/url';
import { isLanguageAvailable } from '@angular-commons/shared/utility/tb-common';

@Injectable({
  providedIn: 'root'
})
export class WildCardChildRoutesGuard implements CanActivate {

  constructor(private router:Router, private route: ActivatedRoute,
              private store: Store<{}>,
              private platformService: PlatformService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let idOrSlug = route.params.idOrSlug;
    let childIdOrSlug = route.params.childIdOrSlug;
    let isCurrentAffairs = idOrSlug === 'current-affairs';
    let locale = route.params.locale || '';
    if(idOrSlug.includes(".") || childIdOrSlug.includes(".")){
      // custom page urls will never have a '.' in them (it indicates that most likely a file was requested)
      this.platformService.gotoErrorPage();
      return of(false);
    }

    if(route.params.languageCode && route.params.languageCode === 'en'){
      let url = getOriginFromURL(this.platformService.href) + '/' + idOrSlug + '/' + childIdOrSlug;
      this.platformService.redirect(url,301);
      return of(false);
    }

    if(languageCodeMap[idOrSlug]){
      //idOrSlug should not be any language code
      this.platformService.gotoErrorPage();
      return of(false);
    }

    if(idOrSlug && childIdOrSlug) {
      let params:any = {idOrSlug, childIdOrSlug, updateRoute: true, language: this.platformService.getContentLang(), maxChildPages: isCurrentAffairs ? 0 : CUSTOM_CHILD_MAX_TOTAL_CHILD_PAGES, maxPrevCrosslinks: CUSTOM_CHILD_MAX_PREV_CROSS_LINKS, maxNextCrosslinks: CUSTOM_CHILD_MAX_NEXT_CROSS_LINKS};

      if (isCurrentAffairs){
        if (childIdOrSlug.startsWith('for-')) {
          let slugArr = childIdOrSlug.split('-');
          if (slugArr?.length > 3) {
            let month = slugArr[1];
            let year = slugArr[2];
            let currentDate = getDateByMonth(month, year);
            this.store.dispatch(getMonthlyCurrentAffairs({
              language: this.platformService.getContentLang(),
              date: currentDate.substr(2),
              month: getMonthIndex(month),
              year
            }));
          }
        }
        else {
          params.caType = 'categoryTag';
        }
      }

      this.store.dispatch(resetWildCardRoutes());
      this.store.dispatch(resetWildCardChildRoutes());
      this.store.dispatch(getWildCardChildRoutes({...params}));

      // had to skip 1 to skip first store default select response.
      return this.store.select(selectWildCardChildRoutes).pipe(skipInitialState(), map((item: any) => {
        let queryLanguage = this.platformService.getQueryParamValue('language').toLowerCase();
        if(!(item.slugType)){
          if(queryLanguage && queryLanguage !== 'english'){
              this.platformService.redirect('/'+idOrSlug+'/'+childIdOrSlug,301); //redirect to english url once
              return false;
          }
          this.platformService.gotoErrorPage();
          return false;
        }
      
        else if(item.slugType === PAGE_TYPE_TARGET){
          if(route.params.languageCode && !item.availableLanguages?.length){
            this.platformService.gotoErrorPage();
            return false;
          }
          if(route.params.languageCode && (!languageCodeMap[route.params.languageCode] || !isLanguageAvailable(languageCodeMap[route.params.languageCode],item.availableLanguages))){
              this.platformService.gotoErrorPage();
              return false;
          }
          
          if(!route.params.languageCode && isLanguageAvailable(queryLanguage,item.availableLanguages)){
            this.platformService.redirect(getLanguageSegmentUrl(queryLanguage,this.platformService.href),301);
          }
        }
        else if(item.slugType === PAGE_TYPE_CUSTOM && item.availableLanguages?.length && !(currentAffairsRegex.test(idOrSlug) || currentAffairsRegex.test(childIdOrSlug))){
          let queryLanguage = this.platformService.getQueryParamValue('language').toLowerCase();
          let isLanguageSegmentFlow = !!route.params.languageCode;
          let isQueryParamFlow = !!queryLanguage;
          if(isQueryParamFlow && !isLanguageSegmentFlow && langMap[queryLanguage]){
            if(!isLanguageAvailable(queryLanguage,item.availableLanguages)){
              this.platformService.gotoErrorPage();
              return false;
            }
            let segmentedLanguageUrl = getLanguageSegmentUrl(queryLanguage,this.platformService.href);
            this.platformService.redirect(segmentedLanguageUrl,301);
            return false;
          }
          else if(!isQueryParamFlow && isLanguageSegmentFlow && languageCodeMap[route.params.languageCode] && !isLanguageAvailable(languageCodeMap[route.params.languageCode], item.availableLanguages)){
            this.platformService.gotoErrorPage();
            return false;
          }
          else if(isQueryParamFlow && isLanguageSegmentFlow){
            let segmentedLanguageUrl = getLanguageSegmentUrl(languageCodeMap[route.params.languageCode],this.platformService.href); //segmented language without language query param
            this.platformService.redirect(segmentedLanguageUrl,301);
            return false;
        }
        }
        else if(!((item.slugType === PAGE_TYPE_TARGET) || (item.slugType == PAGE_TYPE_CUSTOM))){
          this.platformService.gotoErrorPage();
          return false;
        }
        if (locale && !item.locale.some(lc => lc.code.toLowerCase() === locale.toLowerCase())) {
          this.platformService.redirect('/' + idOrSlug + '/' + childIdOrSlug, 301);
          return false;
        }
        return true;
      }))
    }
  }
}
