import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { HeaderLoggedOut} from "@features/header/header-logged-out/header-logged-out";
import {GlobalSearchBarComponent} from "@features/header/components/global-search-bar/global-search-bar.component";
import {SideNavComponent} from "@features/header/components/side-nav/side-nav.component";
import {LanguageSelectorComponent} from "@features/header/components/language-selector/language-selector.component";
import {ProfileDropdownComponent} from "@features/header/components/profile-dropdown/profile-dropdown.component";
import {CoreModule} from "@core/core.module";
import {FormsModule} from "@angular/forms";
import { RequestCallbackModule } from '../request-callback/request-callback.module';
import {HeaderLoggedIn} from "@features/header/header-logged-in/header-logged-in";
import {NotificationsComponent} from "@features/header/components/notifications/notifications.component";
import {GlobalSearchBarModalComponent} from "@features/header/components/global-search-bar-mweb/global-search-bar-modal.component";
import {PassButtonComponent} from "@features/header/components/pass-btn/pass-button.component";
import {RouterModule} from "@angular/router";
import {HeaderComponent} from "@features/header/header.component";
import { PlutusHeader } from './plutus-header/plutus-header';
import { InternationalHeader } from './international-header/international-header';


@NgModule({
    declarations: [
        HeaderComponent,
        HeaderLoggedOut,
        GlobalSearchBarComponent,
        SideNavComponent,
        LanguageSelectorComponent,
        ProfileDropdownComponent,
        HeaderLoggedIn,
        NotificationsComponent,
        GlobalSearchBarModalComponent,
        PassButtonComponent,
        PlutusHeader,
        InternationalHeader
    ],
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        RouterModule,
        RequestCallbackModule
    ],
    exports: [
        HeaderComponent,
        HeaderLoggedOut,
        GlobalSearchBarComponent,
        SideNavComponent,
        LanguageSelectorComponent,
        ProfileDropdownComponent,
        HeaderLoggedIn,
        NotificationsComponent,
        GlobalSearchBarModalComponent,
        PassButtonComponent,
        PlutusHeader,
        InternationalHeader
    ]
})
export class HeaderModule { }
