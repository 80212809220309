<ng-container>
  <footer class="footer" *ngIf="showFooter">
    <div class="wrapper">
      <div class="footer-wrapper">
        <div class="footer-column">
          <svg width="178" height="40" class="footer-logo">
            <use xlink:href="#testbook-logo-white"></use>
          </svg>
          <div class="brand-text">Testbook Edu Solutions Pvt. Ltd.</div>
        </div>

        <div class="footer-column">
          <div class="h4">Company</div>
          <div class="category-item">
            <a target="_self" [routerLink]="generateUrl(urlList.ABOUT_US.url)">{{urlList.ABOUT_US.title}}</a>
          </div>
        </div>
        <div class="footer-column">
          <div class="h4">Important Links</div>
          <div class="category-item">
            <a [routerLink]="generateUrl(urlList.SAT_EXAM.url)">{{urlList.SAT_EXAM.title}}</a>
            <a [routerLink]="generateUrl(urlList.ACT_EXAM.url)">{{urlList.ACT_EXAM.title}}</a>
            <a [routerLink]="generateUrl(urlList.SAT_PRACTICE_TEST.url)">{{urlList.SAT_PRACTICE_TEST.title}}</a>
            <a [routerLink]="generateUrl(urlList.SAT_SCORES_GUIDE.url)">{{urlList.SAT_SCORES_GUIDE.title}}</a>
            <a [routerLink]="generateUrl(urlList.ACT_SCORES_GUIDE.url)">{{urlList.ACT_SCORES_GUIDE.title}}</a>
          </div>
        </div>

        <div class="footer-column">
          <div class="h4">Follow us on</div>
          <ul class="category-item">
            <li class="fl-social">
              <a
                href="//www.facebook.com/testbookdotcom"
                target="_blank"
                rel="noopener"
                class="tb-fb d-inline-block align-top"
              >
                <svg width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#facebook"></use>
                </svg>
              </a>
              <a
                href="//twitter.com/testbookdotcom"
                target="_blank"
                rel="noopener"
                class="tb-tw d-inline-block align-top"
              >
                <svg
                  class="d-inline-block align-top"
                  width="18"
                  height="18"
                  fill="#86a1ae"
                >
                  <use xlink:href="#twitter"></use>
                </svg>
              </a>
              <a
                href="//www.linkedin.com/company/testbook-com"
                target="_blank"
                rel="noopener"
                class="tb-li d-inline-block align-top"
              >
                <svg
                  class="d-inline-block align-top"
                  width="18"
                  height="18"
                  fill="#86a1ae"
                >
                  <use xlink:href="#linkdin"></use>
                </svg>
              </a>
              <a
                href="//www.instagram.com/testbookdotcom/"
                target="_blank"
                rel="noopener"
                class="tb-ig d-inline-block align-top"
              >
                <svg
                  class="d-inline-block align-top"
                  width="18"
                  height="18"
                  fill="#86a1ae"
                >
                  <use xlink:href="#instagram"></use>
                </svg>
              </a>
              <a
                href="//www.youtube.com/c/TestbookSuperCoaching"
                target="_blank"
                rel="noopener"
                class="tb-yt d-inline-block align-top"
              >
                <svg
                  class="d-inline-block align-top"
                  width="20"
                  height="20"
                  fill="#86a1ae"
                >
                  <use xlink:href="#youtube"></use>
                </svg>
              </a>
              <a
                href="//www.quora.com/profile/Testbook-6"
                target="_blank"
                rel="noopener"
              >
                <svg class="d-inline-block align-top" width="15" height="18">
                  <use xlink:href="#quora"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="divider"></div>
      <div class="copyright-wrapper">
        <div class="copyright">
          Copyright © 2014-2024 Testbook Edu Solutions Pvt. Ltd.: All rights
          reserved
        </div>
      </div>
    </div>
  </footer>
</ng-container>
