import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {
    arrayModel,
    objModel, pipeModel,
    propertyMaps, sortBy,
    SourceProjectionMapper,
    withFallback
} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {getDateByFormat, isValidDate, toShortFormat} from "@shared/utility/date-utilities";
import {htmlspecialchars_decode} from "@shared/utility/string-utilities";
import { SeoMenuWrapper } from "../page-seo/seo-menu.adapter";
import { PurchaseInfo } from "../purchaseInfo";


export class _AvailableLanguage extends baseModel {
    @propertyMaps('code', _.str)
    public code;
  
    @propertyMaps('lang', _.str)
    public lang;
  }
  setupModel(_AvailableLanguage, {});

export class _Locale extends baseModel {
    @propertyMaps(['code'], _.str)
    public code;
}
setupModel(_Locale, {});

export class _SuperGroupInfo extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('isPrimary', _.bool)
    public isPrimary;
}
setupModel(_SuperGroupInfo, {type: 'target-page'});

export class _TargetGroupInfo extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('isPrimary', _.str)
    public isPrimary;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(_TargetGroupInfo, {type: 'target-page'});

export class _TargetProperties extends baseModel {
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('subTitle', _.str)
    public subTitle;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('logo', _.str)
    public logo;

    @propertyMaps('passText', _.str)
    public passText;

    @propertyMaps('superGroupInfo', arrayModel(_SuperGroupInfo), _SuperGroupInfo)
    public superGroupInfo;

    @propertyMaps('groupInfo', arrayModel(_TargetGroupInfo), _TargetGroupInfo)
    public targetGroupInfo;

    @propertyMaps('primaryTargetGroup', _.obj)
    public primaryTargetGroup;

    @propertyMaps('purchaseInfo', arrayModel(PurchaseInfo),PurchaseInfo)
    public purchaseInfo: Array<PurchaseInfo>

}
setupModel(_TargetProperties, {type: 'target-page'});

function decodeHtmlDescription(descriptionArr) {
    return descriptionArr.map(item => {
        item.value = htmlspecialchars_decode(item.value);
        return item;
    })
}

export class _TargetPageSection extends baseModel {
    @propertyMaps('heading', _.str)
    public heading;

    @propertyMaps('subHeading', _.str)
    public subHeading;

    @propertyMaps('description', pipeModel(decodeHtmlDescription))
    public description;
}
setupModel(_TargetPageSection, {type: 'target-page'});

export class _TargetPageSeo extends baseModel {
    @propertyMaps('meta', _.obj)
    public meta;

    @propertyMaps('og', _.obj)
    public og;
}
setupModel(_TargetPageSeo, {});

export class _Faq extends baseModel {
    @propertyMaps(['question','ques'])
    public question;

    @propertyMaps(['answer','ans'])
    public answer;
}
setupModel(_Faq, {});

function transformFeatureDetails(items) {
    let returnObj = {};
    for(let item of items) {
        returnObj[item.type] = item;
    }
    return returnObj;
}

export class _FeatureDetail extends baseModel{
    @propertyMaps()
    public type

    @propertyMaps()
    public description;

}
setupModel(_FeatureDetail, {type: 'target-page'});

export function filterBlankFaq(items) {
    return items.filter(item => (item.question && item.answer));
}

export class LatestUpdates extends baseModel{
    @propertyMaps('content', _.str)
    public content;
    @propertyMaps('updatedOn', transformUpdatedOn)
    public updatedOn;

    @propertyMaps('updatedOn')
    public updatedOnRawDate;
}
setupModel(LatestUpdates, {});

export class StudyPlan extends baseModel{
    @propertyMaps('heading', _.str)
    public heading;

    @propertyMaps('subHeading', _.str)
    public subHeading;

    @propertyMaps('ctaTitle', _.str)
    public ctaTitle;

    @propertyMaps('ctaUrl', _.str)
    public ctaLink;
}
setupModel(StudyPlan, {});

export class _TargetPageProperties extends baseModel {

    @propertyMaps('heading', _.str)
    public heading;

    @propertyMaps('subHeading', _.str)
    public subHeading;

    @propertyMaps('sections', arrayModel(_TargetPageSection), _TargetPageSection)
    public sections;

    @propertyMaps('childPages', _.arr)
    public childPages;

    @propertyMaps('featureDetails', pipeModel(arrayModel(_FeatureDetail), transformFeatureDetails), _FeatureDetail)
    public featureDetails;

    @propertyMaps('seo',objModel(_TargetPageSeo), _TargetPageSeo)
    public seo;

    @propertyMaps('menus', objModel(SeoMenuWrapper), SeoMenuWrapper)
    public menus

    @propertyMaps('faq', pipeModel(arrayModel(_Faq), filterBlankFaq), _Faq)
    public faq;

    @propertyMaps('studyPlan', objModel(StudyPlan), StudyPlan)
    public studyPlan;

    @propertyMaps('faqTitle', _.str)
    public faqTitle;

    @propertyMaps('lastUpdatedOn', transformUpdatedOn)
    public lastUpdatedOn;

    @propertyMaps('lastUpdatedOn')
    public lastUpdatedOnRawDate;

    @propertyMaps('latestUpdate', objModel(LatestUpdates), LatestUpdates)
    public latestUpdate;

    @propertyMaps('availableLanguages', pipeModel(withFallback([{code:'en',lang:'english'}]),arrayModel(_AvailableLanguage)), _AvailableLanguage)
    public availableLanguages;

    @propertyMaps(['local','locale'], arrayModel(_Locale),_Locale)
    public locale;

}
setupModel(_TargetPageProperties, {type: 'target-page'});

export function transformUpdatedOn(lastUpdatedOn=''){
    if(lastUpdatedOn==='0001-01-01T00:00:00Z' || lastUpdatedOn == ''){
      return '';// we don't want default date here as we would use it for checks
    }
    return getDateByFormat(lastUpdatedOn,'short','long');
  }

function processTargetProperties(item) {
    if(item.targetGroupInfo && item.targetGroupInfo.length > 0) {
        item.primaryTargetGroup = item.targetGroupInfo.find(item => item.isPrimary);
        if(item.primaryTargetGroup?.title) {
            //temporary hack. Request URL from backend
            item.primaryTargetGroup.href = '/' + item.primaryTargetGroup.title.toLowerCase().split(' ').join('-');
        }
    }
    return item;

}

export class _Target extends baseModel{

    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('stage',_.str)
    public stage;

    @propertyMaps('slug', _.str)
    public slug;

    @propertyMaps('properties', pipeModel(objModel(_TargetProperties), processTargetProperties), _TargetProperties)
    public properties;

    @propertyMaps('pageProperties', objModel(_TargetPageProperties), _TargetPageProperties)
    public pageProperties;

    @propertyMaps('stats', _.obj)
    public stats;

    @propertyMaps('slug', _.str)
    public slugs;

}
setupModel(_Target,{type: 'target-page'});

export class _TargetJob extends baseModel {
    @propertyMaps('datePosted', _.str)
    public datePosted;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('hiringOrg', _.str)
    public hiringOrg;

    @propertyMaps('jobLocation', _.str)
    public jobLocation;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('validThrough', _.str)
    public validThrough;

    @propertyMaps('applicantLocReq', _.str)
    public applicantLocReq;

    @propertyMaps('baseSalary', _.str)
    public baseSalary;

    @propertyMaps('employmentType', _.str)
    public employmentType;

    @propertyMaps('orgLogoUrl', _.str)
    public orgLogoUrl;
}
setupModel(_TargetJob, {type: 'target-page'});

function transformJobs(jobsArr) {
    return jobsArr;
}

function processDates(event, stage, originalObj) {
    if(originalObj && originalObj.dateStr) {
        event.dateStr = originalObj.dateStr;
    }
    else {
        event.dateStr = '';
    }
    event.dateStr += stage + ' :- <br>';
    if(event.isRange) {
        event.dateStr += event.dates.map(item => toShortFormat(item.from) + ' - ' + toShortFormat(item.to)).join('<br>-');
    }
    else {
        event.dateStr += toShortFormat(event.on);
    }
    event.dateStr += ' <br>';
    return event;
}

export function processTargetEvents(arr) {
    let validEvents = [
        "Registration",
        "Admit Card Release",
        "Results",
        "Exam Dates",
        "Answer key release",
        "Cut off release"
    ];
    let stageObj = {};

    for(let stage of arr) {
        let tempEventsObj = {};
        for(let event of stage.events) {
            tempEventsObj[event.value] = event;
        }
        stageObj[stage.value] = tempEventsObj;
    }

    let returnObj = {};

    for(let eventValue of validEvents) {
        for(let stage in stageObj) {
            if(stageObj[stage][eventValue] && stageObj[stage][eventValue].showOnWeb) {
                returnObj[eventValue] = processDates(stageObj[stage][eventValue], stage, returnObj[eventValue]);
            }
        }
    }
    return _TargetOverview.adapt(returnObj);
}

export class _TargetOverview extends baseModel{
    @propertyMaps("Registration")
    public registration;

    @propertyMaps("Admit Card Release")
    public admitCardRelease;

    @propertyMaps("Exam Dates")
    public examDates;

    @propertyMaps("Answer key release")
    public answerKeys;

    @propertyMaps("Results")
    public results;

    @propertyMaps("Cut off release")
    public cutOffRelease;
}
setupModel(_TargetOverview, {type: 'target-page'})

export class _TargetEvent extends baseModel {
    @propertyMaps('value', _.str)
    public value;

    @propertyMaps('titles', _.str)
    public titles;

    @propertyMaps('isRange', _.bool)
    public isRange;

    @propertyMaps('on', _.str)
    public on;

    @propertyMaps('dates', _.arr)
    public dates;

    @propertyMaps('isTentative', _.bool)
    public isTentative;

    @propertyMaps('showOnWeb', _.bool)
    public showOnWeb;
}
setupModel(_TargetEvent, {type: 'target-page'});

export class _TargetStage extends baseModel {
    @propertyMaps('value', _.str)
    public value;

    @propertyMaps('events', _.str)
    public events;
}
setupModel(_TargetStage, {type: 'target-page'});

export class _TargetInfo extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps()
    public targetId;

    @propertyMaps()
    public year;

    @propertyMaps()
    public noOfVacancies;

    @propertyMaps()
    public noOfRegCandidates;

    @propertyMaps('targetJob', pipeModel(arrayModel(_TargetJob), transformJobs), _TargetJob)
    public targetJob;

    @propertyMaps('stages', pipeModel(arrayModel(_TargetStage), processTargetEvents), _TargetStage)
    public stage;

    @propertyMaps()
    public notificationPdf;

    @propertyMaps()
    public salary;

    @propertyMaps()
    public age;

    @propertyMaps()
    public qualification;

    @propertyMaps()
    public applicationLink;

    @propertyMaps()
    public applicationFee;

    @propertyMaps()
    public lastDateToApply;

    @propertyMaps()
    public registrationStart;

    @propertyMaps()
    public location;

    @propertyMaps()
    public organisation;

    @propertyMaps()
    public postName;

    @propertyMaps()
    public overviewComponentsCount;

    @propertyMaps()
    public registrationDateStr;

    @propertyMaps()
    public lastUpdated;

    @propertyMaps()
    public createdOn;

    public lastDateToApplyStr;

    public registrationStartStr;

    @propertyMaps()
    public isDateTentative;

    @propertyMaps()
    public isVacancyTentative;

}
setupModel(_TargetInfo, {type: 'target-page'});

function processTargetInfo (targetInfo) {
    let overviewComponentsCount = 0;

    if(targetInfo.qualification) {
        overviewComponentsCount += 1;
    }

    if(targetInfo.salary) {
        overviewComponentsCount += 1;
    }

    if(targetInfo.noOfVacancies) {
        overviewComponentsCount += 1;
    }

    if(targetInfo.lastDateToApply && isValidDate(targetInfo.lastDateToApply)) {
        overviewComponentsCount += 1;
        targetInfo.lastDateToApplyStr = toShortFormat(targetInfo.lastDateToApply);
    }

    if(targetInfo.registrationStart && isValidDate(targetInfo.registrationStart)) {
        overviewComponentsCount += 1;
        targetInfo.registrationStartStr = toShortFormat(targetInfo.registrationStart);
    }

    targetInfo.overviewComponentsCount = overviewComponentsCount;

    return targetInfo;
}

export class _ChildPageSlug extends baseModel {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('slug', _.str)
    public slug;

    @propertyMaps('updatedOn', _.date)
    public updatedOn;

    @propertyMaps(['local','locale'], arrayModel(_Locale),_Locale)
    public locale;
}
setupModel(_ChildPageSlug, {type: 'target-page'});

export class _TargetWrapper extends baseModel {
    @propertyMaps('target', objModel(_Target), _Target)
    public target;

    @propertyMaps('targetInfo', pipeModel(objModel(_TargetInfo), processTargetInfo), _TargetInfo)
    public targetInfo;

    @propertyMaps('childPages', pipeModel(arrayModel(_ChildPageSlug)), _ChildPageSlug)
    public childPages;
}
setupModel(_TargetWrapper,{type: 'targets'});

export class ChildPageSlug extends baseModel {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('slug', _.str)
    public slug;

    @propertyMaps('updatedOn', _.date)
    public updatedOn;

    @propertyMaps(['local','locale'], arrayModel(_Locale),_Locale)
    public locale;
}
setupModel(ChildPageSlug, {type: 'target-page'});

export class TargetWrapper extends baseModel {
    @propertyMaps('target')
    public target;

    @propertyMaps('targetInfo')
    public targetInfo;

    @propertyMaps('childPages')
    public childPages;
}
setupModel(TargetWrapper,{type: 'targets'});

export class GetTargetInfoApi{
    static apiEndpoint = 'v1/target/{idOrSlug}/page-info';

    static projection;
    static get __projection(){
        if(!GetTargetInfoApi.projection){
            GetTargetInfoApi.projection = JSON.stringify(new SourceProjectionMapper(_TargetWrapper).map());
        }
        return GetTargetInfoApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetTargetInfoApi.apiEndpoint.replace('{idOrSlug}', params.idOrSlug),{__projection:GetTargetInfoApi.__projection});
    }
}

export class _TargetDetailsWrapper {
    @propertyMaps('targets', arrayModel(_Target), _Target)
    public targets;
}
setupModel(_TargetDetailsWrapper, {});

export class GetMultpleTargetDetailsApi {
    static apiEndpoint = 'v1/custom-pages/target';

    static projection;
    static get __projection(){
        if(!GetMultpleTargetDetailsApi.projection){
            GetMultpleTargetDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(_TargetDetailsWrapper).map());
        }
        return GetMultpleTargetDetailsApi.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(GetMultpleTargetDetailsApi.apiEndpoint, { targetIds: params.targetIds, limit: params.limit , __projection: GetMultpleTargetDetailsApi.__projection});
    }
}

export class GetPypTargetsByGoalApi {
    static apiEndpoint = 'v1/previous-year-papers/goal/:goalId/targets';
    static projection;
    static get __projection(){
        if(!GetPypTargetsByGoalApi.projection){
            GetPypTargetsByGoalApi.projection = JSON.stringify(new SourceProjectionMapper(_TargetDetailsWrapper).map());
        }
        return GetPypTargetsByGoalApi.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(GetPypTargetsByGoalApi.apiEndpoint.replace(':goalId', params.goalId), {...params,__projection:GetPypTargetsByGoalApi.__projection});
    }
}